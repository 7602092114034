import {orderBy as _orderBy} from 'lodash';
import consts from "../constants/consts";

const dateFormat = require("dateformat");

export const getArticleLinks = (article) => {
    article.category = _orderBy(article.category, ['position'], ['asc']);
    let category = article.category.find(item => !!item.parent) || {};
    if (!category.parent) {
        category = article.category.find(item => item.treeParent !== null || item.position === 1);
        category.parent = category.treeParent
    }
    let topCategory = article.category.find(item => !item.parent);
    const categoryPath = () => {
        if (!category.parent) {
            category = article.category.find(item => item.treeParent !== null || item.position === 1);
            category.parent = category.treeParent
        }
        return `/${category.parent ? category.parent.slug + '/' : ''}${category.slug}`;
    }
    const getReadMore = () => {
        return `${categoryPath()}/${article.slug}`;
    }
    return {
        category,
        categoryPath,
        getReadMore,
        topCategory
    }
}

export const getRandomArticles = (numberOfElements, array) => {
    return array.sort(() => 0.5 - Math.random()).slice(0, numberOfElements);
}

export const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export const googleScriptLoadingDelay = () => {
    return !document.querySelector('script[src*="ad.tallmanads_2.0.3.js"]') ? consts.SCRIPT_LOADING_TIME_WAIT : 1;
    //return !document.querySelector('script[src*="ad.tallmanads_2.0.2.js"]') ? 1 : 1;
}

export const adDisplayDelay = () => {
    return googleScriptLoadingDelay() + 10;
}

export const formatDate = (date = new Date(), format='dddd, dS mmmm, yyyy') => {
    return dateFormat(date, format);
}

export const delayMultiplicationFactor = (isMobile = false) => {
    return isMobile ? 2.5 : 1;
}

export const isBrowser = () => typeof window !== "undefined";

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}