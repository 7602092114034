// The standard metadata will be overwritten dynamically (articles, authorData)
module.exports = {
    metadata: {
        defaultTitle: 'Pension Times',
        titleTemplate: '%s',
        author: 'Pension Times',
        facebookId: 'fb:page_id',
        twitterId: 'twitter:account_id',
        url: 'https://www.pensiontimes.co.uk',
        description: 'At Pension Times, we aim to be a one-stop resource for over 50’s in the UK. We’ll try to bring you information about everything you need to know as you get older. Here at Pension Times, we recognise that people are staying active for longer.',
        shortDescription: 'At Pension Times, we aim to be a one-stop resource for over 50’s in the UK.'
    }
};